import TMC from '@autonomic/browser-sdk';
import serviceDefs from '../services/serviceDefs';
import { upperFirst } from 'lodash';
import merge from 'lodash/merge';

import { generateEntityPath } from './entity';
import { RESPONSIVE_TABLE_PAGES_TO_PRELOAD, NOOP } from '../constants';
import shared from '../shared';

function genGenericHandlers(op) {
  return op + (op === 'list' ? 'Entities' : 'Entity') + 'Success';
}

export function enhanceSdkEndpoint(service, endpointLabel, actions, genHandlers) {
  return enhanceEndpoint(
    service[endpointLabel],
    { serviceAlias: `${service.label}-service` }, // NOTE it may not always be the case
    actions,
    genHandlers
  );
}

export function addResponseHook(response, onResponse) {
  const originalNextPage = response._nextPageFn.bind(response); // not ideal
  const nextPageFn = () => {
    const nextRespProm = originalNextPage();
    if (nextRespProm) { // `undefined` when there isn't a next page
      return nextRespProm
        .then(resp => addResponseHook(resp, onResponse));
    }
  };
  response.nextPageFn = nextPageFn;

  return onResponse(response);
}

function enhanceEndpoint(endpoint, props, actions, genHandlers=genGenericHandlers) {
  function handle(handlerName, params) {
    if (typeof actions[handlerName] !== 'function') {
      /* eslint-disable no-console */
      console.warn(`Warning: "${handlerName}" function couldn't be found in ActionCreators.`);
      /* eslint-enable no-console */
    } else {
      actions[handlerName](params);
    }
  }

  async function listPages({ endpoint, args=[], action='list', onSuccessfulResponse=NOOP, initPagesToLoad=1 }) {
    let fetchMethod;
    if (typeof action === 'string') {
      fetchMethod = endpoint[action].bind(endpoint);
    }
    else if (typeof action === 'function') {
      fetchMethod = action;
    }
    else {
      throw new Error(`${fetchMethod} is not a valid string or function${endpoint && ' on ' + endpoint.label}`);
    }

    let pageNum = 0;

    let resp = await fetchMethod(...args);
    onSuccessfulResponse(resp, ++pageNum);
    while (--initPagesToLoad && resp.hasNextPage) {
      resp = await resp.nextPage();
      onSuccessfulResponse(resp, ++pageNum);
    }

    resp[Symbol.asyncIterator] = async function*() {
      for (let nextResp of resp) {
        const _nextResp = await nextResp;
        onSuccessfulResponse(_nextResp, ++pageNum);
        yield _nextResp;
      }
    };
    return resp;
  }

  // define custom handlers for each action supported by the endpoint
  const enhanced = endpoint.actions.reduce((obj, op) => {
    obj[op] = {
      value: (...args) => {

        // NOTE: endpoint.label must be the same as the subview to block subview enhancement
        const { enhanceEndpointActions={} } = ((props.subviews && props.subviews[endpoint.label]) || props);
        // enhanced action handlers can be disabled in the service yamls.
        if (enhanceEndpointActions[op] === false) {
          return endpoint[op](...args);
        }

        function onResponse(response) {
          const { serviceAlias } = props;

          const { idProp } = endpoint;
          const pkField = Array.isArray(idProp) ? idProp[0] : idProp;
          const handlerName = genHandlers(op, endpoint);

          let data = response.items ? response.items : response.data;
          let path = generateEntityPath(endpoint, serviceAlias); // parent entity paths and actions are handled in EF

          // Some APIs (**cough cough, geofence**) will return an empty object
          if (!data || typeof data === 'object' && !Object.keys(data).length && args.length && typeof args !== 'object') {
            // DELETE and some custom methods doesn't have a response body,
            // so we're referencing to the original id property (args[0])
            path.push(args[0].toString());
          }
          else if (op !== 'list' && pkField in data) {
            path.push(data[pkField].toString());
          }

          // detect if this is the first page and if so replace the previous items
          const replace = response.isFirstPage;

          handle(handlerName, { path, data, pkField, replace });

          return response;
        }

        return endpoint[op](...args).then(
          response => addResponseHook(response, onResponse)
        );
      }
    };

    return obj;
  }, {});

  enhanced.listPages = {
    value: ({
      args = [],
      pagesToLoad = RESPONSIVE_TABLE_PAGES_TO_PRELOAD,
      action = 'list',
      replace = false,
      handler = 'listEntitiesSuccess'
    }) => {
      const { serviceAlias } = props;
      const { idProp } = endpoint;
      const pkField = Array.isArray(idProp) ? idProp[0] : idProp;
      const path = generateEntityPath(endpoint, serviceAlias);

      let handleResp = handler;
      if (typeof handler === 'string') {
        handleResp = resp => handle(handler, { path, data: resp.items, pkField, replace });
      }
      else if (typeof handler !== 'function') {
        throw new Error(`${handler} is not a valid string or function on ${endpoint.label}`);
      }

      return listPages({
        endpoint,
        action,
        args,
        initPagesToLoad: pagesToLoad,
        onSuccessfulResponse: handleResp
      });
    }
  };

  // define custom handlers for child endpoints recursively
  for (let oKey of Object.keys(endpoint)) {
    if (oKey.startsWith('get') && oKey.endsWith('Endpoint')) {
      enhanced[oKey] = { value: (...args) => enhanceEndpoint(endpoint[oKey](...args), props, actions) };
    }
  }

  // and here is all the magic
  return Object.create(endpoint, enhanced);
}

export default function apiFactory(serviceAlias, entityAlias, actions, apiVersion) {
  const serviceDef  = serviceDefs[serviceAlias];
  const entityDef   = serviceDef.entities[entityAlias];
  apiVersion = apiVersion || entityDef.apiVersion || serviceDef.version;

  const serviceName = 'name' in serviceDef ? serviceDef.name : serviceAlias;
  const entityName  = 'name' in entityDef ? entityDef.name : entityAlias;

  const service     = new TMC.services[upperFirst(serviceName)]({ apiVersion });
  const props       = { serviceAlias, ...entityDef };

  return enhanceEndpoint(service[entityName], props, actions);
}

export function loadJoinedResources(sources, loadedResources, actions) {
  const joinResources = new Map();
  const promises = [];

  for (let source of sources) {
    const { service, entity } = source;
    joinResources.set(`${service}-${entity}`, source);
  }

  joinResources.forEach(resource => {
    //Temporarily removing this check to address issues where a single entity is in the store.
    // let data = loadedResources.get(`${resource.service}-${resource.entity}`);

    // check whether data was already loaded (this will usually occur when
    // multiple entity attributes has the same source defined)

    //Temporarily removing this check to address issues where a single entity is in the store.
    // if (!(data || {}).size) {
      let resEndpoint = apiFactory(resource.service, resource.entity, actions);
      if (resEndpoint) {
        promises.push(resEndpoint.listPages({ pagesToLoad: Infinity }));
      }
    // }
  });

  return Promise.all(promises);
}

export function get(path, extras={}) {
  extras = merge({}, { headers: { 'au-account-id': shared.accountId }}, extras);
  return shared.transporter.get(path, extras);
}
